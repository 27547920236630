import { useContext, useEffect, useCallback } from 'react';
import { useQuery } from 'react-apollo';

import { useSessionData, useBooleanFeatureFlag } from '@confluence/session-data';
import { SPAViewContext } from '@confluence/spa-view-context';

import {
	useGetOnboardingState,
	useOnboardingState,
	deserializeState,
} from '../onboardingState/onboardingState';
import { EDITOR_ONBOARDING_STATE_KEYS } from '../../constants/onboarding-state/onboarding-editor-constants';
import { ONBOARDING_INTENT_EXPERIENCES } from '../../constants/onboarding-state/onboarding-constants';
import { useMigratedUser } from '../useMigratedUser/useMigratedUser';

import { UserPersonalizationQuery } from './UserPersonalizationQuery.experimentalgraphql';
import type {
	PersonalizationQuery,
	PersonalizationQueryVariables,
} from './__types__/UserPersonalizationQuery';

const ESTABLISHED_USER_TRAIT = 'confluence_is_established_user';

interface SetOnboardingEligibilityProps {
	/* Onboarding state key that indicates if a user is eligible for an experiment */
	onboardingStateEligibleValue: boolean;
	/* Boolean for if the feature flag is enabled */
	featureFlagEnabled: boolean;
	/* Onboarding state key that will be set to `true`*/
	onboardingStateEligibleKey: string;
}

export const useOnboardingEligible = () => {
	const { isNewUser } = useContext(SPAViewContext);
	const { isLicensed, isLoggedIn, userId } = useSessionData();
	const { isMigratedUser, isMigratedUserReady } = useMigratedUser();
	const isOnboardingEligible = useBooleanFeatureFlag('confluence.frontend.onboarding.experience');

	const { setOnboardingState } = useOnboardingState();
	const eligibleNewUser = isLoggedIn && isLicensed && isNewUser;

	/**
	 * Skip the query for checking whether the user is an established user if:
	 * 1. The master onboarding FF is OFF
	 * 2. The user is not licensed
	 * 3. The user is not logged in (anonymous)
	 * 4. The user is not new (hasn't viewed a confluence page)
	 */
	const skipQuery = !eligibleNewUser || !isOnboardingEligible;

	const {
		data: userPersonalizationQueryData,
		loading: userPersonalizationQueryLoading,
		error: userPersonalizationQueryError,
	} = useQuery<PersonalizationQuery, PersonalizationQueryVariables>(UserPersonalizationQuery, {
		variables: {
			userId: userId!,
		},
		skip: skipQuery,
	});

	const attributes =
		userPersonalizationQueryData?.experimentalUserPersonalization?.attributes ?? [];
	// if we skip querying for personalization,
	// we can assume that user should be establishedUser and skip over onboarding
	const isEstablishedUser =
		skipQuery || attributes.find((attr) => attr?.name === ESTABLISHED_USER_TRAIT)?.value === 'true';

	const isEstablishedUserReady = !userPersonalizationQueryLoading && !userPersonalizationQueryError;

	const {
		data: onboardingStateData,
		loading: onboardingStateLoading,
		error: onboardingStateError,
	} = useGetOnboardingState(
		[
			...Object.values(EDITOR_ONBOARDING_STATE_KEYS),
			...Object.values(ONBOARDING_INTENT_EXPERIENCES),
		],
		!isOnboardingEligible || !isLoggedIn || !isLicensed,
	);

	const { editorOnboardingEligiblity, isOnboardingExperienceEligibleUser } =
		deserializeState(onboardingStateData);

	const checkAndSetOnboardingEligibility = useCallback(
		({
			onboardingStateEligibleValue,
			featureFlagEnabled,
			onboardingStateEligibleKey,
		}: SetOnboardingEligibilityProps) => {
			if (
				!isEstablishedUser &&
				isEstablishedUserReady &&
				onboardingStateData &&
				featureFlagEnabled &&
				!onboardingStateEligibleValue
			) {
				void setOnboardingState({
					key: onboardingStateEligibleKey,
					value: 'true',
				});
			}
		},
		[isEstablishedUser, onboardingStateData, setOnboardingState, isEstablishedUserReady],
	);

	// IBO Tagging
	useEffect(() => {
		checkAndSetOnboardingEligibility({
			onboardingStateEligibleValue: isOnboardingExperienceEligibleUser,
			featureFlagEnabled: true,
			onboardingStateEligibleKey: ONBOARDING_INTENT_EXPERIENCES.ONBOARDING_EXPERIENCE_ELIGIBLE,
		});
	}, [isOnboardingExperienceEligibleUser, checkAndSetOnboardingEligibility]);

	// Editor Onboarding Experiment
	useEffect(() => {
		if (isOnboardingEligible && userPersonalizationQueryData && onboardingStateData) {
			// only check for users with Eligibility not set
			if (editorOnboardingEligiblity === null || editorOnboardingEligiblity === undefined) {
				if ((isMigratedUserReady && isMigratedUser) || isEstablishedUser) {
					// Set EDITOR_ONBOARDING_ELIGIBLE_NEW: false for migrated users and established users
					void setOnboardingState({
						key: EDITOR_ONBOARDING_STATE_KEYS.EDITOR_ONBOARDING_ELIGIBLE_NEW,
						value: 'false',
					});
				} else {
					// Set EDITOR_ONBOARDING_ELIGIBLE_NEW: true for users that are new to confluence
					void setOnboardingState({
						key: EDITOR_ONBOARDING_STATE_KEYS.EDITOR_ONBOARDING_ELIGIBLE_NEW,
						value: 'true',
					});
				}
			}
		}
	}, [
		isOnboardingEligible,
		eligibleNewUser,
		isEstablishedUser,
		isMigratedUserReady,
		isMigratedUser,
		editorOnboardingEligiblity,
		userPersonalizationQueryData,
		onboardingStateData,
		onboardingStateLoading,
		onboardingStateError,
		setOnboardingState,
	]);

	return {
		isEstablishedUser,
		isEstablishedUserReady,
		loading: userPersonalizationQueryLoading,
		error: userPersonalizationQueryError,
		skipQuery,
		eligibleNewUser,
	};
};
